// WE USE LESS FOR THE MAIN THEME ONLY, RSUITE ONLY SUPPORT LESS

@import url('~typopro-web/web/TypoPRO-Iosevka/TypoPRO-iosevka-term-regular.css');

html, body, #root{
  height: 100%;
}

body {
  background-color: #F9F9F9;
}

body.page--login {
  background-color: #000;
}

body.page--login .logo {
  color: #fff;
}
.ag-theme-alpine-dark .ag-root-wrapper {
  border: none !important;
}

.ag-theme-alpine-dark {
  font-size: 10px !important;
  font-family: "TypoPRO Iosevka Term" !important;
}

.ag-theme-alpine-dark .ag-row {
  font-size: 10px !important;
}

.ag-theme-alpine-dark .ag-cell {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.ag-theme-alpine-dark .ag-header-cell {
  font-size: 10px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
@base-color: #002147;