.logo{

  
  color: #000;

  img{
    width: 40px;
    position: relative;
    top: -2px;
  }

  font-size: 20px;
  font-family: 'TypoPRO Iosevka Term';

  &__small{
    img{
      width: 30px;
    }
    font-size: 20px;
  }
}